import React from 'react';
// @ts-ignore
import { Helmet } from 'react-helmet';
import Nav from '@components/common/Nav';
import Footer from '@components/sections/Footer';
import Layout from '../../layouts';
import Doc from '@layouts/docs';

const Terms: React.FC = () => {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="BubaJobs | Portal de empleo en Argentina" />
        <title>BubaJobs | Portal de empleo en Argentina</title>
        <link rel="canonical" href="http://bubajobs.com.ar/" />
      </Helmet>
      <Nav activeSection={'none'} />
      <Doc>
        <div className="wrapper-doc">
          <div className="principal-text">
            <h1>TÉRMINOS Y CONDICIONES</h1>
            <p>
              El presente documento describe los términos y condiciones
              generales (los “<u>Términos y Condiciones</u>”) aplicables al uso
              de los servicios ofrecidos por la Plataforma BubaJobs (en adelante
              “<u>BubaJobs</u>” o la “<u>Plataforma BubaJobs</u>” o los “
              <u>Servicios BubaJobs</u>” y/o los “<u>Servicios</u>” y/o “
              <u>BubaJobs</u>” indistintamente). Cualquier persona, física o
              jurídica (en adelante “<u>Usuario</u>” y/o en plural “
              <u>Usuarios</u>”) que desee acceder y/o utilizar BubaJobs podrá
              hacerlo sujetándose a estos Términos y Condiciones, junto con
              todas las demás políticas y principios que rigen BubaJobs y que
              son incorporados al presente por referencia.
            </p>
            <p>
              Al utilizar BubaJobs aceptas expresamente estos Términos y
              Condiciones. En caso de no estar de acuerdo con las condiciones
              establecidas en estos Términos y Condiciones, los cuales tienen
              carácter de obligatorios y vinculantes junto con todos sus anexos
              y documentos referenciados, deberás abstenerte de utilizar
              BubaJobs y los Servicios.
            </p>{' '}
            <p>
              Para aceptar estos términos y condiciones debes leer, entender y
              aceptar todas las condiciones establecidas en los Términos y
              Condiciones, en las Políticas de Privacidad así como en los demás
              documentos incorporados a los mismos por referencia, de forma
              previa a su registración como Usuario de BubaJobs, optando de
              forma expresa por recibir los mismos y toda otra información
              pertinente por medios digitales.
            </p>
          </div>
          <div className="secondary-text">
            <h2>1 – CAPACIDAD</h2>
            <p>
              BubaJobs sólo está disponible para personas que tengan capacidad
              legal para contratar según la legislación vigente en la
              jurisdicción donde contrata y para trabajar. Los menores de edad,
              según la legislación correspondiente, deberán contar con la
              autorización de sus representantes legales. No pueden utilizar los
              Servicios las personas que no tengan esa capacidad, los menores de
              edad sin autorización o los Usuarios que hayan sido suspendidos
              temporalmente o inhabilitados definitivamente. Si estás
              registrando un Usuario en nombre de una persona jurídica, debes
              tener capacidad para contratar a nombre de tal entidad y de
              obligar a la misma en los términos de los presentes Términos y
              Condiciones.
            </p>
          </div>
          <div className="secondary-text">
            <h2>2 – REGISTRACIÓN</h2>
            <p>
              Para crear un usuario debes registrarte utilizando tu número de
              teléfono, tu cuenta de correo electrónico, tu cuenta de Facebook®
              o tu cuenta de Google® y completar el formulario de registración
              en todos sus campos obligatorios con datos válidos para poder
              utilizar BubaJobs. Como Usuario deberás completarlo con tu
              información personal de manera exacta, precisa y verdadera (los
              “<u>Datos Personales</u>”) y asumes el compromiso de actualizar los Datos
              Personales conforme resulte necesario. Al crear el Usuario prestas
              expresa conformidad para que BubaJobs utilice diversos medios para
              identificar tus datos personales, asumiendo la obligación de
              revisarlos y mantenerlos actualizados. BubaJobs no se
              responsabiliza por la certeza de los Datos Personales de los
              Usuarios. Los Usuarios garantizan y responden, en cualquier caso,
              de la veracidad, exactitud, vigencia y autenticidad de sus Datos
              Personales.
            </p>
            <p>
              Una vez creado el Usuario podrás acceder a tu cuenta personal (la
              “<u>Cuenta</u>”) mediante el ingreso de tu usuario y clave de seguridad
              personal elegida (la “<u>Clave de Seguridad</u>”), la cual te comprometes
              a mantener en confidencialidad.
            </p>
            <p>
              Tu Cuenta de Usuario es personal, única e intransferible, y está
              prohibido que un mismo Usuario registre o posea más de una Cuenta.
              En caso de que BubaJobs detecte distintas Cuentas que contengan
              datos coincidentes o relacionados, podrá proceder a cancelar,
              suspender o inhabilitar dichas cuentas.
            </p>
            <p>
              Como Usuario serás responsable por todas las operaciones
              efectuadas en tu Cuenta, pues el acceso a la misma está
              restringido al ingreso y uso de tu Clave de Seguridad, de tu
              conocimiento exclusivo. Asimismo, te comprometes a notificar a
              BubaJobs en forma inmediata y por medio idóneo y fehaciente,
              cualquier uso no autorizado de tu Cuenta, así como el ingreso por
              terceros no autorizados a la misma. Se aclara que está prohibida
              la venta, cesión o transferencia de la Cuenta bajo ningún título.
            </p>
            <p>
              BubaJobs se reserva el derecho de rechazar cualquier solicitud de
              registración o de cancelar una registración previamente aceptada,
              sin que esté obligado a comunicar o exponer las razones de su
              decisión y sin que ello genere algún derecho a indemnización o
              resarcimiento.
            </p>
          </div>
          <div className="secondary-text">
            <h2>3 – TIPOS DE USUARIOS</h2>
            <p>
              La Plataforma BubaJobs está diseñada a los fines de acercar a sus
              usuarios a ofertas de trabajo y empleo (el “Usuario candidato”) y
              a los usuarios empleadores a buscar empleados (el “Usuario
              Empleador”). Como usuario de BubaJobs, puedes elegir entre ser un
              usuario empleador o un usuario que busca empleo.
            </p>
            <p>
              El Usuario candidato reconoce y acepta que para ingresar a la
              Plataforma BubaJobs y/o a la aplicación, deberá hacerlo con su
              nombre de usuario de Google®, LinkedIn® o Facebook®, por lo que
              reconoce y acepta que deberá contar con un nombre de usuario y/o
              perfil válido y activo en las mencionadas redes sociales y está de
              acuerdo en someterse de manera expresa a los términos y
              condiciones de uso de Google®, LinkedIn® y/o Facebook®.
            </p>
            <p>
              Para que el Usuario empleador pueda adquirir los Servicios
              ofrecidos por BubaJobs deberá generar un usuario con la siguiente
              información:
            </p>
            <p>
              Registrar sólo personas físicas empleadoras o sociedades
              existentes y debidamente inscriptas ante la Administración Federal
              de Ingresos Públicos (AFIP) y, en caso de corresponder, ante el
              registro público de comercia correspondiente, conforme la ley
              aplicable y consignar únicamente los datos requeridos de tales
              Empresas. El Usuario Empleador debe realizar un único registro por
              empleador. La persona que realice la inscripción del Usuario
              Empleador deberá acreditar facultad suficiente para realizar la
              inscripción y será consignada como persona de contacto entre
              BubaJobs y la Empresa. Se admite sólo 1 (un) registro por cada
              persona de contacto.
            </p>
            <p>
              Validación de Cuentas. BubaJobs validará el cumplimiento de los
              requisitos para el registro en la Plataforma atendiendo a su mejor
              criterio o a reclamos por parte de terceros y se reserva el
              derecho de rechazar o dar de baja el registro de cualquier Usuario
              por motivos comerciales o por incumplimientos contractuales
              previos (ya acreditados o presuntos), o por haberse recibido
              denuncias de terceros. Asimismo, BubaJobs se reserva el derecho de
              rechazar una solicitud de registro y/o de cancelar y/o suspender,
              temporal y/o definitivamente a un Usuario o una cuenta en caso de
              detectar incongruencias o inconsistencias en la información
              provista por un Usuario o en caso de detectar actividades
              sospechosas, sin que tal decisión genere para el Usuario derechos
              de indemnización o resarcimiento.
            </p>
            <p>
              La registración del Usuario Empleador significará la aceptación
              total de estos términos y condiciones y de las tarifas aplicables
              por los Servicios. Luego de realizado lo anterior, al Usuario
              empleador se le habilitará el acceso a la Plataforma, para que
              pueda ingresar con su nombre de usuario y contraseña, respecto de
              cuyos datos de acceso el Usuario empleador será el exclusivo
              responsable de su conservación y custodia de manera segura. Al
              hacerlo, BubaJobs le otorgará al Usuario empleador el derecho a
              utilizar el Servicio. Este derecho no es exclusivo y no es
              transferible. El uso de la Plataforma por parte del Usuario
              empleador puede estar sujeto al pago de las tarifas pactadas con
              BubaJobs.
            </p>
            <p>
              El Usuario Candidato, para buscar empleo o trabajo, debe cargar su
              curriculum a la Plataforma BubaJobs, con toda la información
              relevante para que los empleadores puedan analizar tu perfil y tu
              historial de trabajo. Tendrás el control total sobre todos los
              datos que ingreses a la Plataforma. Para que el Usuario candidato
              pueda acceder a los Servicios ofrecidos por la Plataforma, así
              como interactuar con los demás Usuarios, deberá ingresar con su
              nombre de usuario y/o a través de las plataformas de Google® y/o
              Facebook® y completar o en su caso actualizar, su perfil en la
              Plataforma, respecto de cuyos datos de acceso el Usuario candidato
              será el exclusivo responsable de su conservación y custodia de
              manera segura. Al ingresar a la Plataforma, el Usuario candidato
              deberá aceptar los presentes Términos y Condiciones de uso. Al
              registrarse en la Plataforma se le otorgará al Usuario el derecho
              a utilizar el Servicio. Este derecho no es exclusivo y no es
              transferible. BubaJobs se reserva el derecho de denegar el acceso
              a la Plataforma en caso de violación de los presentes términos y
              condiciones.
            </p>
            <p>
              El Usuario Candidato podrá también optar por realizar una
              súper-postulación, mediante la cual se le dará mayor visibilidad a
              su Perfil para determinadas búsquedas. La súper-postulación estará
              sujeta a las tarifas que a tal efecto informe BubaJobs a través de
              su plataforma.
            </p>
          </div>
          <div className="secondary-text">
            <h2>4 – SERVICIOS DE BUBAJOBS. CARGOS.</h2>
            <p>
              BubaJobs está diseñada a los fines de acercar al Usuario a los
              bienes y servicios prestados por otros Usuarios empleadores de
              bienes y servicios que no se relacionan de modo alguno con
              BubaJobs. BubaJobs, a través de los Servicios de la Plataforma
              BubaJobs, actúa como un mero intermediario entre los Usuarios
              candidatos y Usuarios empleadores. BubaJobs no es ni será en
              ningún caso responsable por los servicios prestados por los
              Usuarios ajenos a BubaJobs.
            </p>
            <p>
              BubaJobs facilita el contacto entre los Usuarios Empleadores y
              Usuarios Candidatos para la: (i) creación de búsquedas de trabajo
              por parte de los Usuarios Empleadores, categorizados por posición,
              requerimientos y ubicación geográfica; (ii) posibilidad de
              interacción entre los Usuarios mediante la remisión mutua de Datos
              Personales de contacto; (iii) sistema de suscripción a servicio de
              alertas de búsquedas de trabajo disponibles en la Plataforma.
              Adicionalmente, se podrán prever otras funciones y servicios
              específicas para cada Usuario.
            </p>
            <p>
              La utilización de los Servicios requerirá la activación de los
              servicios de geolocalización de los Usuarios (así como también los
              restantes permisos que la Plataforma solicite), lo que a su vez
              permitirá que estos sean visibles para otros Usuarios de la
              Plataforma. El Usuario podrá hacer uso de todas los Servicios que
              se encuentren disponibles en la Plataforma para el Perfil que
              hubiese seleccionado, en la medida que otorgue los permisos que se
              requieran a tal efecto.
            </p>
            <p>
              Cargos. Los Servicios podrán ser tanto de uso gratuito como pagos.
              La Plataforma contendrá indicaciones sobre los Servicios que
              generen cargos, los que deberán ser abonados en los términos aquí
              establecidos. Sin perjuicio de ello, BubaJobs puede decidir, en
              cualquier momento y con una notificación previa de 5 (cinco) días
              corridos, dejar de ofrecer de forma gratuita sus Servicios de
              forma total o parcial.
            </p>
            <p>
              Acciones. Todas las acciones que el Usuario realice en la
              Plataforma serán consideradas como válidas, legítimas, auténticas,
              no requiriéndose confirmación adicional alguna.
            </p>
            <p>
              Estadísticas: BubaJobs podrá elaborar, con la información recabada
              a través de la Plataforma, toda clase de estadísticas anónimas
              relativas al mercado laboral en general, que procesará y
              compartirá con los Usuarios de la Plataforma a los fines de
              mejorar los Servicios y ampliar las posibilidades de los Usuarios
              y estrategias. Al aceptar estos términos y condiciones los
              Usuarios aceptan que su información sea utilizada de manera
              anónima, garantizando que no podrá identificarse sus titulares
              (art. 7 inciso 2 Ley Nº 25.326).
            </p>
          </div>
          <div className="secondary-text">
            <h2>5 – CARGOS.</h2>
            <p>
              Cargos. La Plataforma incluye, dentro de sus Servicios, algunos
              que se encuentran o podrían encontrarse en el futuro, sujetas al
              pago de precios o tarifas (los “Cargos”), ya sea mediante pago
              puntual o por suscripción (membresía). Adicionalmente, podrán
              existir beneficios especiales (incluido, pero no limitado a
              destaque de búsquedas de trabajo) también sujetos a Cargos (los
              “Beneficios”).
            </p>
            <p>
              Los Cargos corresponderán a los publicados en la Plataforma en el
              momento de su contratación o bien los anunciados a través de la
              Plataforma o por correo electrónico a los Usuarios y con una
              anticipación mínima de 5 (cinco) días corridos de su entrada en
              vigencia o bien los acordados mediante orden de servicio o
              contrato separado. Se deja expresa constancia de que dichos Cargos
              no incluyen en ningún caso los gastos derivados del método de pago
              elegido por el Usuario, ni aquellos impuestos o tasas que, en su
              caso, correspondan, los cuales se aplicarán antes del pago
              efectivo, ni ninguna otra prestación y/o servicio que no esté
              expresamente detallada en la orden particular, los cuales son a
              cargo y costo total y exclusivo del Usuario.
            </p>
            <p>
              Pago y Emisión de Factura. Los Cargos serán abonados mediante los
              métodos de pago disponibles en la Plataforma. BubaJobs emitirá una
              factura una vez realizado el pago de un Cargo. Los Usuarios
              tendrán a su disposición dicha factura dentro de su panel de
              acceso en la Plataforma.
            </p>
            <p>
              Reembolsos. El Usuario puede eliminar su Cuenta o su Perfil, así
              como sus alertas o búsquedas de trabajo o Servicios o Beneficios
              requeridos en cualquier momento, sin derecho a reembolso del Cargo
              pagado. En ningún caso la suspensión del servicio o la no
              activación por incumplimiento de las normas de registro o de
              cualesquiera de los Términos y Condiciones dará lugar al reembolso
              de cualquier Cargo. BubaJobs se reserva la potestad de suspender o
              cancelar cualquier Cuenta y/o Servicio y/o Beneficio total o
              parcialmente, temporal o definitivamente, e impedir o limitar el
              acceso del Usuario, sin derecho a reembolso del Cargo pagado, en
              caso de: (i) uso fraudulento o no acorde con estos Términos y
              Condiciones; o (ii) no recibirse el correspondiente pago del Cargo
              en el plazo estipulado, ello sin perjuicio de la aplicación de
              intereses y penalidades por impago y del reclamo de las sumas
              pendientes. El Usuario tendrá derecho al reembolso de la parte no
              devengada del Cargo en caso de incumplimiento grave de BubaJobs de
              estos Términos y Condiciones o en caso de que BubaJobs termine la
              relación con el Usuario en cualquier momento, pero sin causa, con
              o sin previo aviso (facultad que se reserva).
            </p>
            <p>
              Mora. La mora en el cumplimiento de las obligaciones asumidas por
              el Usuario será automática, sin necesidad de notificación judicial
              o extrajudicial alguna, y devengará los intereses moratorios a la
              tasa mensual equivalente a 1 y ½ veces la tasa promedio del Banco
              de la Nación Argentina para operaciones de descuento de documentos
              comerciales, desde la fecha de la mora y hasta la fecha del
              efectivo pago. La falta de pago en término de la factura remitida
              por BubaJobs facultará a este último a suspender inmediatamente
              cualquiera de las Servicios y/o la Cuenta hasta su efectivo pago,
              sin que ello genere derecho al Usuario a reclamo ni indemnización
              alguna. Si dicho pago no se regulariza, BubaJobs podrá, a su
              exclusivo criterio, resolver la relación contractual con el
              Usuario y reclamar los daños y perjuicios ocasionados. BubaJobs
              podrá imputar cualquier pago que reciba del Usuario – aún en
              concepto de otra Funcionalidad contratada – al pago de las sumas
              adeudadas.
            </p>
          </div>
          <div className="secondary-text">
            <h2>6 – REGLAS PARA BÚSQUEDAS DE USUARIOS CANDIDATOS.</h2>
            <p>
              Reglas Aplicables a Publicación de Búsquedas de Usuarios
              Candidatos
            </p>
            <p>
              Los Usuarios Empleadores que publiquen búsquedas para reclutar
              Usuarios Candidatos deberán cumplir con el procedimiento de la
              Plataforma, que incluye los siguientes requisitos:
            </p>
            <p>
              (a) El Usuario Empleador deberá especificar, con el mayor detalle
              posible, los requisitos buscados en el Usuario Candidato y el área
              en la que deberá desempeñarse el Usuario Candidato; (b) detallar
              la modalidad de contratación, especificando que se busca
              individuos interesados en prestar sus servicios como trabajadores
              independientes o en relación de dependencia, así como el sistema
              de trabajo remoto o presencial y el lugar de prestación. BubaJobs
              se reserva la potestad de retirar de la Plataforma aquellas
              búsquedas que infrinjan lo aquí indicado, así como también la
              potestad de sujetarlas a verificación previa (no encontrándose
              obligada a hacerlo).
            </p>
            <p>
              BubaJobs podrá aplicar filtros o restringir la exhibición de
              resultados de búsquedas o de Usuarios en el mapa de la Plataforma
              por razones de experiencia del usuario o de funcionamiento de la
              Plataforma o en virtud de las estadísticas recabadas y perfiles
              aplicados.
            </p>
            <p>
              Los Usuarios Empleadores deberán abstener de: (1)
              replicar/duplicar búsquedas o publicar búsquedas que no sean
              ofertas laborales o en infracción de la legislación laboral
              vigente; (2) publicar búsquedas de trabajo que requieran del
              candidato una inversión de capital y/o contraprestación alguna
              vedada por ley; (3) publicar búsquedas de trabajos prohibidos por
              la legislación y/o que induzcan a error; (4) publicar búsquedas de
              trabajo que no sean veraces y reales; (5) publicar datos de
              contacto en la descripción de la búsqueda (números telefónicos,
              direcciones, correos electrónicos, etcétera) o información (links,
              por ejemplo) que redirijan a páginas webs externas a la Plataforma
              o información confidencial de terceros no autorizados; (6)
              publicar propagandas políticas de ningún tipo; (7) publicar
              búsquedas que hagan mención de manera directa o indirecta a
              actividades ilícitas; o (8) incitar o proponer al Usuario
              Candidato a continuar con el registro para la efectiva postulación
              al puesto en otro sitio web o aplicación distinta a la Plataforma.
            </p>
            <p>
              En relación al uso que realicen los Usuarios Empleadores de la
              información recibida en respuesta a las búsquedas, estas se
              obligan a: mantener la más estricta confidencialidad de los datos
              recibidos en virtud del uso de la Plataforma y los Servicios;
              acceder a los datos de los Usuarios Candidatos de conformidad con
              lo establecido en estos Términos y Condiciones y únicamente para
              finalidades de reclutamiento; tratar la información de los
              Usuarios Candidatos con la máxima reserva y confidencialidad y de
              acuerdo con lo establecido en la Ley Aplicable y abstenerse de
              publicar dichos datos en otras plataformas y/o utilizar dichos
              datos para cualquier fin que no sea el específicamente contratado.
            </p>
            <p>
              No ceder o transferir los datos personales de los Usuarios
              Candidatos a personas humanas o jurídicas distintas de las que
              pudieran haber sido expresamente autorizadas y para usos distintos
              de la finalidad de la Plataforma.
            </p>
            <p>
              Proteger los datos personales de los Usuarios Candidatos a los que
              tenga acceso, garantizando su confidencialidad e impidiendo que
              terceros no autorizados puedan tener acceso a los mismos.
            </p>
            <p>
              El acceso a los datos de los Usuarios Candidatos postulados a cada
              búsqueda se permite durante un plazo máximo de 5 días.
            </p>
            <p>
              En ningún caso, se otorga permiso a los Usuarios Empleadores para
              almacenar los datos de los Usuarios Candidatos para otras
              búsquedas de trabajo ni para constituir una base de datos privada
              de currículos ni para cualquier otro uso distinto a la finalidad
              de la Plataforma, salvo que ello sea permitido expresamente y por
              escrito por BubaJobs, ya sea mediante el uso de la herramienta de
              software de reclutamiento ofrecida por el mismo o mediante
              autorización expresa por escrito.
            </p>
          </div>
          <div className="secondary-text">
            <h2>
              7 – MENSAJES AUTOMÁTICOS POR MENSAJES DE TEXTO, CORREO ELECTRÓNICO
              Y/O OTROS MEDIOS. ENCUESTAS.
            </h2>
            <p>
              Al crear tu Cuenta, aceptas que BubaJobs te pueda enviar mensajes
              de Whatsapp, mensajes de texto (SMS), correos electrónicos, SAT
              Push y/o cualquier otro tipo de mensaje automático existente o que
              se cree en el futuro (los “Mensajes”) como parte de la actividad
              comercial normal de BubaJobs. Podrás solicitar la no recepción de
              ciertos mensajes por parte de ciertos Usuarios desde la
              configuración de tu Cuenta en cualquier momento. Al solicitar la
              no recepción de ciertos mensajes o contenidos podrás estar
              limitando el alcance de los Servicios BubaJobs y podrá afectar al
              uso que usted haga de los Servicios.
            </p>
            <p>
              Asimismo, al utilizar BubaJobs aceptas recibir encuestas de
              calidad predefinidas a través de los Mensajes, ya sea de parte de
              BubaJobs o de algún otro Usuario. El tratamiento de dicha
              información se encuentra regulado en las Políticas de Privacidad
              que forman parte de los presentes Términos y Condiciones.
            </p>
          </div>
          <div className="secondary-text">
            <h2>
              8 – DATOS PERSONALES. PRIVACIDAD DE LA INFORMACIÓN. INFORMACIÓN
              COMPARTIDA CON TERCEROS.
            </h2>
            <p>
              Para utilizar BubaJobs, como Usuario deberás facilitar
              determinados datos de carácter personal. Tu información personal
              se procesa y almacena en servidores o medios magnéticos que
              mantienen altos estándares de seguridad y protección tanto física
              como tecnológica. Para mayor información sobre la privacidad de
              los Datos Personales y casos en los que será revelada la
              información personal, se pueden consultar nuestras Políticas de
              Privacidad.
            </p>
            <p>
              El usuario autoriza desde ya, y por la mera aceptación de los
              presentes Términos y Condiciones, a que BubaJobs utilice los datos
              personales obtenidos mediante el uso de BubaJobs y de los
              Servicios para la creación de una base de datos en los términos de
              la ley aplicable a cada jurisdicción según lo informado en el
              apartado de Políticas de Privacidad, sin derecho a indemnización o
              compensación alguna. Como Usuario podrás, desde la configuración
              de su Cuenta, elegir qué información compartir con otros Usuarios,
              entendiendo que la limitación de la información a compartir
              limitará el alcance de los servicios de BubaJobs. BubaJobs utiliza
              los datos de los Usuarios únicamente en forma interna para mejorar
              la calidad de los Servicios, y mejorar las promociones y la
              experiencia en la red. BubaJobs no comparte ni vende tus datos
              personales con terceros sin tu expreso consentimiento.
            </p>
            <p>
              Asimismo, BubaJobs no será responsable por la eventual difusión de
              los datos personales que efectúen personas ajenas a BubaJobs y/o
              los terceros autorizados por el Usuario, ni será responsable por
              los eventuales daños y perjuicios que tal circunstancia genere al
              Usuario. La Dirección Nacional de Protección de Datos Personales
              establece que: 1.) “El titular de los datos personales tiene la
              facultad de ejercer el derecho de acceso a los mismos en forma
              gratuita a intervalos no inferiores a seis meses, salvo que se
              acredite un interés legítimo al efecto conforme lo establecido en
              el art. 14, inciso 3 de la Ley 25.326” y 2.) La Dirección Nacional
              de Protección de Datos Personales, Órgano de Control de la Ley Nº
              25.326, tiene la atribución de atender las denuncias y reclamos
              que se interpongan con relación al incumplimiento de las normas
              sobre protección de datos personales.
            </p>
          </div>
          <div className="secondary-text">
            <h2>9 - CONTENIDO PROPORCIONADO POR EL USUARIO.</h2>
            <p>
              BubaJobs podrá, a su sola discreción, permitirle al Usuario cuando
              considere oportuno, que envíe, cargue, publique o de otro modo
              ponga a disposición de BubaJobs contenido e información de texto,
              audio y/o visual, incluidos comentarios y opiniones relativos a
              los Servicios, iniciación de peticiones de apoyo, así como
              promociones (“<u>Contenido de Usuario</u>”). Todo Contenido de Usuario
              facilitado por el mismo seguirá siendo de sus propios dueños. No
              obstante, al proporcionar Contenido de Usuario a BubaJobs podrá
              utilizar el mismo según lo especificado en el apartado de
              Politicas de Privacidad.
            </p>
            <p>
              Al aceptar los presentes Términos y Condiciones declaras y
              garantizas que: (i) eres el único y exclusivo propietario de todo
              el Contenido de Usuario o que tienes todos los derechos,
              licencias, consentimientos y permisos necesarios para otorgar a
              BubaJobs la licencia al Contenido de Usuario como establecido
              anteriormente; y (ii) ni el Contenido de Usuario ni su
              presentación, carga, publicación o puesta a disposición de otro
              modo de dicho Contenido de Usuario, ni el uso por parte de
              BubaJobs del Contenido de Usuario como está aquí permitido,
              infringirán, malversarán o violarán la propiedad intelectual o los
              derechos de propiedad de un tercero o los derechos de publicidad o
              privacidad o resultarán en la violación de cualquier ley o
              reglamento aplicable.
            </p>
            <p>
              Asimismo, no podrás proporcionar Contenido de Usuario que sea
              difamatorio, calumnioso, odioso, violento, obsceno, pornográfico,
              ilícito o de otro modo ofensivo, como determine BubaJobs, a su
              sola discreción, tanto si dicho material pueda estar protegido o
              no por la ley. BubaJobs podrá, a su sola discreción y en cualquier
              momento y por cualquier motivo, sin avisarle previamente, revisar,
              controlar o eliminar el Contenido de Usuario, pero sin estar
              obligada a ello.
            </p>
            <p>
              Toda la información suministrada por BubaJobs a terceros según lo
              aquí consentido por el Usuario, será utilizado por éstos para
              fines comerciales y publicitarios de la manera en que ellos crean
              más conveniente. BubaJobs no tiene ni tendrá responsabilidad
              alguna sobre el uso que los terceros le asignen al Contenido de
              usuario suministrado por el Usuario.
            </p>
          </div>
          <div className="secondary-text">
            <h2>10 – VIOLACIONES DEL SISTEMA O BASES DE DATOS.</h2>
            <p>
              No está permitida ninguna acción o uso de dispositivo, software, u
              otro medio tendiente a interferir tanto en las actividades y
              operatoria de BubaJobs ni del funcionamiento de los Servicios
              BubaJobs. Cualquier intromisión, tentativa o actividad violatoria
              o contraria a las leyes sobre derecho de propiedad intelectual y/o
              a las prohibiciones estipuladas en este contrato harán pasible a
              su responsable de las acciones legales pertinentes, y a las
              sanciones previstas por este acuerdo, así como lo hará responsable
              de indemnizar los daños ocasionados.
            </p>
          </div>
          <div className="secondary-text">
            <h2>11 – SISTEMA DE PUNTUACIONES RECÍPROCAS.</h2>
            <p>
              La Plataforma podrá incluir la puesta a disposición de los
              Usuarios de un sistema de puntuación recíproca en Usuarios
              Candidatos y Usuarios Empleadores que hayan interactuado a través
              de la Plataforma (que podrá incluir comentarios sobre el
              cumplimiento de las obligaciones asumidas por los Usuarios
              Empleadores, así como también sobre el desempeño de los Usuarios
              Candidatos), cuyos resultados se encuentren visibles para todos
              los Usuarios de la Plataforma. Los Usuarios Candidatos podrán en
              cualquier momento optar por no participar del Sistema de
              Puntuaciones Recíprocas de la Plataforma, entendiendo que ello
              podría impactar en sus posibilidades de conseguir empleo a través
              de la Plataforma. BubaJobs declara y garantiza que no conserva una
              base de datos respecto del Sistema de Puntuaciones Recíprocas y
              que los Usuarios podrán en todo momento solicitar la baja del
              sistema.
            </p>
            <p>
              La evaluación se realizará mediante una selección de
              características destacables de los Usuarios (de un menú de
              opciones indicado en la Plataforma) y un puntaje del 1 (uno) al 5
              (cinco). Los Usuarios conocen y aceptan que, en su Perfil, así
              como en las búsquedas de trabajo publicadas, tales evaluaciones
              serán públicamente visibles para otros Usuarios de la Aplicación
              en tanto no hayan optado por no participar del Sistema de
              Puntuaciones Recíprocas. Los Usuarios deberán abstenerse de: (a)
              ofrecer incentivos a los empleados, exempleados o candidatos a
              cambio de evaluaciones y/o comentarios (siendo tales evaluaciones,
              susceptibles de eliminación por BubaJobs); (b) coaccionar o
              intimidar a ninguna persona a realizar evaluaciones. Los Usuarios
              deberán cumplir con todas las reglas de publicación expuestas en
              esta Cláusula al momento de realizar sus evaluaciones y/o
              comentarios, debiendo estos representar su opinión personal. Todos
              los comentarios que se realicen en el Sistema de Puntuaciones
              Recíprocas es y será en todo momento responsabilidad del Usuario
              que la emitió, no siendo BubaJobs responsable bajo ningún
              concepto.
            </p>
            <p>
              Asimismo, la Plataforma puede poner a disposición de los Usuarios
              estadísticas salariales estimadas para determinados puestos de
              trabajo o categorías de puestos de trabajo. Tales estadísticas
              salariales se realizarán con la información sobre salarios
              publicada, en su caso, por los Usuarios Empleadores en sus
              búsquedas de empleo o por los Usuarios Candidatos en sus
              expectativas salariales y no tiene en cuenta consideraciones
              legales como salario mínimo profesional de cada jurisdicción ni la
              modalidad de pago. Las cifras son estimaciones ofrecidas
              únicamente con fines informativos y estadísticos y el grado de
              precisión puede variar, por lo cual cada Usuario debe asegurarse
              y, en todo caso, de confirmar con la Empresa cuál es el salario
              específico de una búsqueda de trabajo publicado.
            </p>
          </div>
          <div className="secondary-text">
            <h2>12 – SANCIONES. SUSPENSIÓN DE OPERACIONES.</h2>
            <p>
              Sin perjuicio de otras medidas, BubaJobs podrá advertir, suspender
              en forma temporal o definitivamente la Cuenta de un Usuario,
              aplicar una sanción que impacte negativamente en la reputación de
              un Usuario, iniciar las acciones que estime pertinentes y/o
              suspender la prestación de los Servicios BubaJobs si (a) se
              quebrantara alguna ley, o cualquiera de las estipulaciones de los
              Términos y Condiciones Generales y demás políticas de BubaJobs;
              (b) si incumpliera sus compromisos como Usuario; (c) si se
              incurriera a criterio de BubaJobs en conductas o actos dolosos o
              fraudulentos; (d) no pudiera verificarse la identidad del Usuario
              o cualquier información proporcionada por el mismo fuere errónea;
              (e) BubaJobs entendiera que las publicaciones u otras acciones
              pueden ser causa de responsabilidad para el Usuario que las
              publicó, para BubaJobs o para los demás Usuarios en general. En
              caso de suspensión definitiva de un Usuario, todo dinero que tenga
              cargado en su cuenta será transferido a la cuenta que el Usuario
              indique, dentro de los 60 (sesenta) días de efectivizarse la
              suspensión definitiva. BubaJobs se reserva el derecho de descontar
              los montos generados por gastos administrativos producto de dicha
              suspensión. En caso de que el Usuario no proporcione una cuenta a
              donde transferir sus créditos dentro de los plazos previstos,
              dichos créditos quedarán a favor de BubaJobs.
            </p>
          </div>
          <div className="secondary-text">
            <h2>13 – RESPONSABILIDAD.</h2>
            <p>
              BubaJobs es una plataforma que conecta a los Usuarios a los fines
              de que estos accedan a bienes y servicios. BubaJobs no será
              responsable respecto de la existencia, calidad, cantidad, estado,
              integridad o legitimidad de los servicios prestados por los
              Usuarios, así como de la capacidad para contratar de los Usuarios
              o de la veracidad de los Datos Personales por ellos ingresados.
              Cada Usuario conoce y acepta ser el exclusivo responsable por los
              servicios que utiliza y declara entender y aceptar que los
              responsables de las notificaciones y mensajes enviados a través de
              la Plataforma son de responsabilidad del Usuario que los envía.
            </p>
            <p>
              BubaJobs no será responsable por el contenido de las búsquedas de
              trabajo, publicaciones de los Usuarios y/o los mensajes y
              notificaciones enviadas por los Usuarios ni por las evaluaciones y
              puntuaciones que realicen los Usuarios de otros Usuarios,
              aceptando cada Usuario que estas serán exhibidas a los Usuarios
              que observen sus Perfiles sin modificaciones y tal cual como han
              sido consignadas. El Usuario conoce y acepta que al aceptar los
              presentes Términos y Condiciones y al utilizar BubaJobs lo hace
              bajo su propio riesgo y bajo su entera responsabilidad. En ningún
              caso BubaJobs será responsable por cualquier daño y/o perjuicio
              que haya podido sufrir el Usuario, debido a las notificaciones y/o
              mensajes recibidos de los Terceros proveedores a través de
              BubaJobs.
            </p>
            <p>
              En caso de que uno o más Usuarios o algún tercero inicien
              cualquier tipo de reclamo o acciones legales contra otro u otros
              Usuarios, Tercero proveedor, todos y cada uno de los Usuarios
              involucrados en dichos reclamos o acciones eximen de toda
              responsabilidad a BubaJobs y a sus directores, gerentes,
              empleados, agentes, operarios, representantes y apoderados. Los
              Usuarios tienen un plazo de 30 (treinta) días desde que se produjo
              el hecho dañoso para iniciar un reclamo o denuncia contra otro u
              otros Usuarios, Tercero proveedor. Una vez vencido este plazo, no
              podrán iniciar un reclamo desde BubaJobs. BubaJobs se reserva el
              derecho de tomar todas las medidas y acciones que crea
              convenientes y necesarias a los fines de solucionar cualquier
              controversia que surja entre los Usuarios y accionar para dar de
              baja cualquier publicación que viole estos Términos y Condiciones
              en un plazo razonable desde la toma de conocimiento.
            </p>
          </div>
          <div className="secondary-text">
            <h2>14 – FALTA DE RESPONSABILIDAD COMÚN.</h2>
            <p>
              Los presentes Términos y Condiciones no crean ningún contrato de
              sociedad, de mandato, de franquicia, o relación laboral entre
              BubaJobs y el Usuario ni existe relación de sociedad o de
              dependencia entre BubaJobs y los Usuarios empleadores de bienes y
              servicios. El Usuario reconoce y acepta que BubaJobs no es parte
              en ninguna operación, ni tiene control alguno sobre la calidad,
              seguridad o legalidad de los mensajes y notificaciones enviados
              por y hacia los Usuarios. BubaJobs no puede asegurar el contenido
              ni la calidad de los mensajes y notificaciones enviadas por y
              hacia los Usuarios. BubaJobs no garantiza la veracidad, legalidad
              ni contenido de la publicidad que contengan dichos mensajes y no
              será responsable por la correspondencia o contratos que el Usuario
              celebre con Terceros o con otros Usuarios.
            </p>
          </div>
          <div className="secondary-text">
            <h2>15 – FALLAS EN EL SISTEMA.</h2>
            <p>
              BubaJobs ofrece sus Servicios con un nivel de competencia y
              diligencia razonable desde el punto de vista comercial. No
              obstante, BubaJobs no tiene la capacidad de garantizar el acceso a
              los Servicios de forma irrestricta sin limitaciones.
            </p>
            <p>
              Ni BubaJobs ni los Terceros proveedores o distribuidores ofrecen
              garantías específicas sobre los Servicios distintas a las
              establecidas de forma expresa en los presentes Términos y
              Condiciones o en las condiciones adicionales. A modo de ejemplo,
              sin que la presente resulte taxativa, BubaJobs no ofrece ningún
              tipo de garantía respecto del contenido cargado por Usuarios o
              Terceros Proveedores, sus funciones específicas, la fiabilidad de
              los servicios ofrecidos por terceros o Usuarios, su
              disponibilidad, ni su capacidad para satisfacer las necesidades
              específicas de los Usuarios. Los Servicios se ofrecen «tal cual».
            </p>
            <p>
              BubaJobs no se responsabiliza por cualquier daño, perjuicio o
              pérdida al Usuario causados por fallas en el sistema, en el
              servidor, en la red de telefonía móvil o en Internet o de los
              Terceros proveedores. BubaJobs tampoco será responsable por
              cualquier virus o mensaje no deseado que pudiera infectar el
              equipo del Usuario como consecuencia del acceso, uso o examen de
              BubaJobs o de los Terceros proveedores. Los usuarios NO podrán
              imputarle responsabilidad alguna, en virtud de perjuicios
              resultantes de dificultades técnicas o fallas en los sistemas o en
              Internet. BubaJobs no garantiza el acceso y uso continuado o
              ininterrumpido de BubaJobs. El sistema puede eventualmente no
              estar disponible debido a dificultades técnicas o fallas de
              Internet o de la red de telefonía móvil, o por cualquier otra
              circunstancia ajena a BubaJobs; en tales casos se procurará
              restablecerlo con la mayor celeridad posible sin que por ello
              pueda imputársele algún tipo de responsabilidad a BubaJobs.
              BubaJobs no será responsable por ningún error u omisión contenidos
              en su sitio web.
            </p>
          </div>
          <div className="secondary-text">
            <h2>16 – PROPIEDAD INTELECTUAL.</h2>
            <p>
              Los contenidos de los mensajes enviados por los Usuarios
              empleadores y las publicidades desplegadas en BubaJobs por parte
              de terceros como así también los programas, bases de datos, redes,
              archivos que permiten al Usuario acceder y usar su Cuenta, las
              imágenes, logos, marcas, etc., son de propiedad de sus respectivos
              dueños y están protegidas por las leyes y los tratados
              internacionales de derecho de autor, marcas, patentes, modelos y
              diseños industriales que apliquen a cada una de ellas. El uso
              indebido y la reproducción total o parcial de dichos contenidos
              por parte de los Usuarios está prohibido, salvo autorización
              expresa y por escrito del respectivo dueño de la propiedad
              intelectual.
            </p>
            <p>
              BubaJobs puede contener enlaces a otros sitios web, lo cual no
              indica que sean propiedad u operados por BubaJobs. En virtud de
              que BubaJobs no tiene control sobre tales sitios, NO será
              responsable por los contenidos, materiales, acciones y/o servicios
              prestados por los mismos, ni por daños o pérdidas ocasionadas por
              la utilización de los mismos, sean causadas directa o
              indirectamente. La presencia de enlaces a otros sitios web no
              implica una sociedad, relación, aprobación, respaldo de BubaJobs a
              dichos sitios y sus contenidos.
            </p>
          </div>
          <div className="secondary-text">
            <h2>17 – PROPIEDAD INTELECTUAL DE BUBAJOBS.</h2>
            <p>
              Toda la propiedad intelectual de BubaJobs y todo otro invento
              desarrollado y comercializado por BubaJobs, así como su marca,
              logo y demás derechos de propiedad intelectual que se detallan que
              se despliegan en BubaJobs son de propiedad exclusiva de BubaJobs y
              de ningún modo se podrá interpretar que los Usuarios, o los
              Terceros proveedores o los Operadores tienen derecho alguno sobre
              BubaJobs y/o cualquier otro invento desarrollado por BubaJobs. Los
              presentes términos y condiciones no transfieren a los Usuarios
              ningún derecho, interés o título de propiedad de cualquier tipo
              sobre los derechos de propiedad intelectual utilizados por
              BubaJobs.
            </p>
          </div>
          <div className="secondary-text">
            <h2>18 – INDEMNIDAD. OBLIGACIÓN DE INDEMNIZACIÓN</h2>
            <p>
              El Usuario acepta indemnizar y eximir de responsabilidad a
              BubaJobs (incluyendo pero no limitando a sus sociedades
              relacionadas, accionistas, sus respectivos directores, gerentes,
              funcionarios, representantes, agentes y empleados) por cualquier
              reclamo o demanda (incluidos los honorarios razonables de
              abogados) formulados por cualquier Usuario y/o tercero por
              cualquier infracción a los Términos y Condiciones de Uso y demás
              Anexos y Políticas que se entienden incorporadas al presente, y/o
              cualquier ley y/o derechos de terceros.
            </p>
            <p>
              El Usuario acepta eximir, defender (según el criterio de
              BubaJobs), indemnizar y deslindar de responsabilidad a BubaJobs y
              a sus accionistas, filiales y subsidiarias, así como a sus
              responsables, directivos, empleados y agentes, contra y frente
              cualesquiera reclamos, sumas de dinero reclamadas por daños y
              perjuicios, pérdidas y gastos, incluido, sin limitación, los
              gastos razonables de asesoría jurídica y contable, que se
              desprendan de, o estén de algún modo relacionados con (i) el
              incumplimiento por su parte de los presentes Términos o de
              nuestras Políticas o Normas, (ii) el uso indebido por su parte de
              BubaJobs o cualesquiera de los Servicios de BubaJobs y/o de los
              Terceros proveedores y/o del Operador, (iii) la interacción por su
              parte con cualquier otro Usuario, Tercero proveedor y/o Operador,
              su utilización de los servicios, y demás Servicios de BubaJobs,
              incluido, sin limitación, cualesquiera lesiones, pérdidas o
              indemnizaciones por daños y perjuicios (ya sean sanciones sin
              finalidad resarcitoria, por daños directos, fortuitos, emergentes
              o de otro tipo) de cualquier índole que surjan en relación con, o
              como resultado de, dicha interacción, (iv) el cobro de cualquier
              impuesto por aplicable a los servicios contratados a un Tercero
              proveedor o (v) la infracción por su parte de cualesquiera leyes,
              normativas o derechos de terceros.
            </p>
          </div>
          <div className="secondary-text">
            <h2>19 – OBSERVACIONES.</h2>
            <p>
              Son bienvenidas las observaciones, comentarios y sugerencias para
              mejorar BubaJobs y alentamos a los Usuarios a enviar todo tipo de
              sugerencias respecto de BubaJobs (“Observaciones”). Puede
              enviarnos Observaciones haciéndonos llegar un correo electrónico,
              a través de la sección “Contacto” de BubaJobs o por otros medios
              de comunicación. Cualquier tipo de Observación que nos envíe será
              considerado como no confidencial y sin que le correspondan
              derechos de propiedad. Al enviarnos Observaciones, usted nos
              concede una licencia no exclusiva, internacional, libre de
              regalías, irrevocable, sub-licenciable y perpetua para utilizar y
              publicar esas ideas y materiales con cualquier fin, sin que medie
              compensación en su favor.
            </p>
          </div>
          <div className="secondary-text">
            <h2>20 – UTILIZACIÓN DE COOKIES.</h2>
            <p>
              BubaJobs utiliza cookies para mejorar el comportamiento de los
              Servicios ofrecidos a los Usuarios, según lo informado en las
              Políticas de Privacidad.
            </p>
          </div>
          <div className="secondary-text">
            <h2>21 – ANEXOS.</h2>
            <p>
              Forman parte integral e inseparable de los Términos y Condiciones,
              los siguientes documentos y/o secciones de BubaJobs incorporados
              por referencia, donde se detallan políticas y/o Términos y
              Condiciones de diferentes servicios ofrecidos en el sitio. Los
              mismos se podrán consultar dentro del sitio mediante el enlace
              abajo provisto o accediendo directamente a las páginas
              correspondientes:
            </p>
            <p>Política de privacidad</p>
            <p>Condiciones adicionales de los Servicios</p>
          </div>
          <div className="secondary-text">
            <h2>22 – MODIFICACIONES DEL ACUERDO</h2>
            <p>
              BubaJobs podrá modificar los Términos y Condiciones en cualquier
              momento haciendo públicos en BubaJobs los términos modificados
              (los “Nuevos Términos y Condiciones”) y/o por otros medios
              electrónicos, de acuerdo a su política de comunicación con los
              Usuarios. Todos los términos modificados entrarán en vigor a los
              10 (diez) días de su publicación. Dichas modificaciones serán
              comunicadas por BubaJobs. Todo usuario que no esté de acuerdo con
              las modificaciones efectuadas por BubaJobs podrá solicitar la baja
              de la Cuenta. Si dentro de los 10 (diez) días de la publicación de
              los Términos y Condiciones actualizados el Usuario no cerró su
              cuenta, se considerará que el usuario ha aceptado los Nuevos
              Términos y Condiciones y dichos términos y condiciones entrarán en
              vigencia y serán vinculantes para el Usuario que los haya
              aceptado.
            </p>
            <p>
              El uso del sitio y/o sus servicios implica la aceptación de estos
              Términos y Condiciones generales de uso de BubaJobs.
            </p>
          </div>
          <div className="secondary-text">
            <h2>23 – JURISDICCIÓN Y LEY APLICABLE</h2>
            <p>
              Este acuerdo estará regido en todos sus puntos por las leyes
              vigentes en la República Argentina.
            </p>
            <p>
              Cualquier controversia derivada del presente acuerdo, su
              existencia, validez, interpretación, alcance o cumplimiento, será
              sometida al arbitraje del Centro Empresarial de Mediación y
              Arbitraje, de acuerdo con la reglamentación vigente de dicho
              tribunal. La sede de dicho arbitraje será la Ciudad Autónoma de
              Buenos Aires y los procedimientos se llevarán a cabo en idioma
              castellano.
            </p>
          </div>
          <div className="secondary-text">
            <h2>24 – DOMICILIO.</h2>
            <p>
              BubaJobs es un producto de propiedad de BubaJobs S.A.S. y a todos
              los efectos legales constituye domicilio legal en Av. Santa Fe
              1193 piso 3 Of. “11” de la Ciudad Autónoma de Buenos Aires,
              República Argentina.
            </p>
          </div>
        </div>
      </Doc>
      <Footer />
    </Layout>
  );
};

export default Terms;
