import styled from 'styled-components';
import { px2rem } from '@styles/styleUtils';
import { device } from '@theme/size';

export const StyledDoc = styled.main`
  display: flex;
  justify-content: center;
  padding: 0 ${px2rem(10)};
  margin-bottom: ${px2rem(56)};

  .wrapper-doc{
    width: 100%;
    max-width: ${px2rem(960)};

    .principal-text {
      h1 {
        font-family: "Messapia", sans-serif;
        font-size: ${px2rem(32)};
        margin-top: ${px2rem(15)};
        margin-bottom: ${px2rem(40)};
      }
    }
    
    .secondary-text {
      h2 { font-size: ${px2rem(24)}; }

      h3 { font-size: ${px2rem(20)}; }

      h4 { font-size: ${px2rem(18)}; }

      h2, h3, h4 {
        font-family: "Lexend Mega", sans-serif;
        margin-bottom: ${px2rem(16)};
      }
    }
  
    .principal-text > p, .secondary-text > p {
      font-family: "Lexend", sans-serif;
      font-size: ${px2rem(16)};
      margin-bottom: ${px2rem(40)};
    }
  }

  @media ${device.mobileM} {
    padding: 0 ${px2rem(16)};
  }

  @media ${device.tablet} {
    padding: ${px2rem(0)} ${px2rem(16)} ${px2rem(52)} ${px2rem(16)};
  }

  @media ${device.laptop} {
    padding: ${px2rem(0)} ${px2rem(10)} ${px2rem(52)} ${px2rem(10)};

    .wrapper-doc{
      width: 100%;
      max-width: ${px2rem(960)};

      .principal-text {
        h1 {
          margin-top: ${px2rem(15)};
          margin-bottom: ${px2rem(24)};
        }
      }

      .secondary-text {
        h2, h3, h4 {
          margin-bottom: ${px2rem(16)};
        }
      }

      .principal-text > p, .secondary-text > p {
        margin-bottom: ${px2rem(28)};
      }
    }
  }
`